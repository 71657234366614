import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../../components/floating-button/floating-button';
import Layout from '../../components/layout';
import React from 'react';

const TechnologiesPage = (props) => {
    const components = props.data.cms.technologyPageTemplate?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <FloatingButton />
            <DynamicZone components={components} />
        </Layout>
    );
};

export default TechnologiesPage;

export const query = graphql`
query ($locale: String!){
  cms {
    technologyPageTemplate(locale: $locale) {
      components {
        ... on STRAPI_ComponentComponentsHeader {
          __typename
          title
          textBoxCustomClasses
          backgroundImage {
              url
          }
          subtitleImages {
            title
            icon {
              url
            }
          }
          headerSubtitle: subtitle
          mobileContentOffset
          contentOffset
          backgroundVideo {
            url
          }
          backgroundImageHeight
        }
        ... on STRAPI_ComponentTimelineTimeline {
          __typename
          timelineAnimationData
          timelineContent
          topPusherHeight
          topPusherHeightMobile
          mobileTimelineImage {
            url
          }
          sectionTitle {
            tailwindClasses
            title
            ytLink
            style
            description
          }
        }
        ... on STRAPI_ComponentHorizontalTabsHorizontalTabs {
          __typename
          topPusherHeightMobile
          topPusherHeight
          tabsDarkBackground
          tabHeight
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          middleImage {
            url
          }
          tabs {
            type
            title
            lottieData
            scrollColor
            subtitle
            link
            labelImage {
              url
            }
            labelColour
            label
            isLabelImageVisibleOnDesktop
            imagesTitles
            imagesMetadata
            images {
              url
            }
            fullWidthImageItemsDirection
          }
        }
        ... on STRAPI_ComponentVerticalTabsVerticalTabs {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            tailwindClasses
            title
            ytLink
            style
            description
          }
          VerticalTabTitle {
            title
            image {
              url
            }
          }
          VerticalTabContent {
            title
            contentTexts
            contentRichText
            contentImages {
              url
            }
            titleImage {
              url
            }
          }
        }
        ... on STRAPI_ComponentCardWithBackgroundImageCardWithBackgroundImage {
          __typename
          topPusherHeight
          topPusherHeightMobile
          cardWithBackgroundImageTitle: title
          cardWithBackgroundImageSubtitle: subtitle
          icon {
            url
          }
          backgroundImage {
            url
          }
        }
        ... on STRAPI_ComponentSeoSeo {
          __typename
          seoDescription
          seoLink
          seoTitle
          seoImages {
            url
          }
        }
        ... on STRAPI_ComponentComponentsDocumentationSection {
          __typename
          topPusherHeight
          topPusherHeightMobile
          sectionTitle {
            description
            style
            tailwindClasses
            title
            titleIcon {
              url
            }
            ytLink
          }
          documentationSectionFile {
            title
            icon {
              url
            }
            file {
              url
            }
          }
        }
      }
    }
  }
}
`;
